var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$i18n.t('Novo Ticket')}},[_c('validation-observer',{ref:"ticketForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.createTicket.apply(null, arguments)}}},[_c('div',[_c('h6',[_vm._v(_vm._s(_vm.$t("Assunto")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"mode":"eager","name":"Subject","vid":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subject","state":errors.length > 0 ? false : null,"placeholder":_vm.$i18n.t('Digite qual é o assunto')},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mt-2"},[_c('h6',[_vm._v(_vm._s(_vm.$t("Descrição do problema")))]),_c('b-form-group',[_c('validation-provider',{attrs:{"mode":"eager","name":"Problem description","vid":"problem-description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"problem-description","state":errors.length > 0 ? false : null,"placeholder":_vm.$i18n.t(
                  'Descreva em detalhes qual é o problema que você deseja tratar com a equipe de suporte'
                ),"rows":"4"},model:{value:(_vm.problemDescription),callback:function ($$v) {_vm.problemDescription=$$v},expression:"problemDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mt-2"},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t( "Gostaria de anexar algum print para nos ajudar a compreender melhor o problema?" ))+" ")]),_c('avatar-img',{attrs:{"textNoImg":'I M G',"rounded":'sm'},on:{"avatarFile":function (val) { return (_vm.printFile = val); }}})],1),_c('div',{staticClass:"mt-2"},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t( "Selecione uma categoria, assim, conseguimos direcionar o seu problema imediatamente para a equipe responsável" ))+" ")]),_c('v-select',{attrs:{"options":_vm.categoryOptions},model:{value:(_vm.categorySelected),callback:function ($$v) {_vm.categorySelected=$$v},expression:"categorySelected"}}),(!_vm.categorySelected.value)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("Por favor, selecione uma categoria")))]):_vm._e(),_c('hr'),_c('alert',{staticClass:"mb-1",attrs:{"text":_vm.textAlert,"color":_vm.colorAlert,"showAlert":_vm.showAlert},on:{"changeVariableAlert":function (val) { return (_vm.showAlert = val); }}}),_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-content-end"},[_c('b-button',{staticClass:"mr-0 mr-sm-1 mb-1 mb-sm-0",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.loadingBtn || !_vm.categorySelected.value}},[(!_vm.loadingBtn)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Enviar ticket"))+" ")]):_c('div',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t("Carregando..."))+" ")],1)]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.push('/support')}}},[_vm._v(_vm._s(_vm.$t("Voltar")))])],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }