<template>
  <b-card :title="$i18n.t('Novo Ticket')">
    <validation-observer ref="ticketForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="createTicket">
        <div>
          <h6>{{ $t("Assunto") }}</h6>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              mode="eager"
              name="Subject"
              vid="subject"
              rules="required"
            >
              <b-form-input
                id="subject"
                v-model="subject"
                :state="errors.length > 0 ? false : null"
                :placeholder="$i18n.t('Digite qual é o assunto')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="mt-2">
          <h6>{{ $t("Descrição do problema") }}</h6>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              mode="eager"
              name="Problem description"
              vid="problem-description"
              rules="required"
            >
              <b-form-textarea
                id="problem-description"
                v-model="problemDescription"
                :state="errors.length > 0 ? false : null"
                :placeholder="
                  $i18n.t(
                    'Descreva em detalhes qual é o problema que você deseja tratar com a equipe de suporte'
                  )
                "
                rows="4"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="mt-2">
          <h6>
            {{
              $t(
                "Gostaria de anexar algum print para nos ajudar a compreender melhor o problema?"
              )
            }}
          </h6>
          <avatar-img
            :textNoImg="'I M G'"
            :rounded="'sm'"
            @avatarFile="(val) => (printFile = val)"
          />
        </div>
        <div class="mt-2">
          <h6>
            {{
              $t(
                "Selecione uma categoria, assim, conseguimos direcionar o seu problema imediatamente para a equipe responsável"
              )
            }}
          </h6>
          <v-select v-model="categorySelected" :options="categoryOptions" />
          <small class="text-danger" v-if="!categorySelected.value">{{
            $t("Por favor, selecione uma categoria")
          }}</small>
          <hr />
          <alert
            :text="textAlert"
            :color="colorAlert"
            :showAlert="showAlert"
            @changeVariableAlert="(val) => (showAlert = val)"
            class="mb-1"
          />
          <div class="d-flex flex-column flex-sm-row justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              class="mr-0 mr-sm-1 mb-1 mb-sm-0"
              :disabled="invalid || loadingBtn || !categorySelected.value"
              ><div v-if="!loadingBtn">
                {{ $t("Enviar ticket") }}
              </div>
              <div v-else>
                <b-spinner small type="grow"></b-spinner>
                {{ $t("Carregando...") }}
              </div></b-button
            >
            <b-button
              variant="outline-primary"
              @click="$router.push('/support')"
              >{{ $t("Voltar") }}</b-button
            >
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@/libs/validations";
import { BFormTextarea, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import AvatarImg from "../global-components/avatar-img/AvatarImg.vue";

export default {
  components: {
    BFormTextarea,
    AvatarImg,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,

    //Validate
    required,
  },
  data() {
    return {
      loadingBtn: false,
      subject: "",
      problemDescription: "",
      printFile: null,
      categorySelected: {
        label: this.$i18n.t("Selecione a categoria"),
        value: null,
      },
      categoryOptions: [
        { label: this.$i18n.t("Dados da conta"), value: "account_data" },
        { label: this.$i18n.t("Financeiro"), value: "financial" },
        { label: this.$i18n.t("Outro"), value: "other" },
        {
          label: this.$i18n.t("Rede de afiliados"),
          value: "affiliate_network",
        },
        { label: this.$i18n.t("Robô trader"), value: "robot_trading" },
      ],
      textAlert: "",
      colorAlert: "warning",
      showAlert: false,
    };
  },
  methods: {
    createTicket() {
      this.$refs.ticketForm.validate().then((success) => {
        if (success) {
          this.loadingBtn = true;
          const data = {
            dataTicket: {
              subject: this.subject,
              description: this.problemDescription,
              category: this.categorySelected.value,
              attachments: [],
            },
            attachments: [],
          };
          if (this.printFile) {
            data.attachments = [this.printFile];
          }
          this.$store
            .dispatch("createTicket", data)
            .then((resp) => {
              this.textAlert = resp.message;
              this.colorAlert = "success";
              this.showAlert = true;
              this.loadingBtn = false;
              setTimeout(() => {
                this.$router.push("/support");
              }, 2000);
            })
            .catch((err) => {
              this.textAlert = err;
              this.colorAlert = "danger";
              this.showAlert = true;
              this.loadingBtn = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>